<i18n>
    {
      "es": {
        "INFO": 'Detalles de la promo',
      },
      "en": {
        "INFO": "Promo details",
      },
      "fr": {
        "INFO": "Détails de la promo",
      },
      "it": {
        "INFO": "Dettagli promo",
      },
      "de": {
        "INFO": "Promo-Details",
      },
      "pt": {
        "INFO": "Detalhes da promoção",
      },
    }
  </i18n>

<template>
  <ModalCourtain :visibility="visibility" @close="cancel" :zIndex="10000">
    <template v-slot:title>
      <div class="flex justify-between items-end w-full bg-white">
        <h2
          class="mx-auto md:mx-0 md:px-4 flex text-lg md:text-xl tracking-wider font-bold text-sanremo-black text-center"
        >
          {{ t('INFO') }}
        </h2>
        <button v-if="!isMobileOrTablet" type="button" @click="cancel">
          <img src="@/assets/svg/icons/icons-close.svg" class="h-7 w-7" />
        </button>
      </div>
      <div>
        <div
          v-if="isMobileOrTablet"
          class="h-px w-full pl-96 -ml-2 bg-neutral-light-grey-1 -mt-2"
        ></div>
      </div>
    </template>
    <template v-slot:content>
      <div class="mt-2 w-full max-w-lg px-4 min-h-[8rem] md:min-h-0">
        <p
          v-html="promo.subtitle"
          class="text-sm text-sanremo-black text-start font-normal -mt-3 leading-7 tracking-wider"
        ></p>
        <p
          v-if="promo?.description"
          v-html="promo.description"
          class="text-sm text-sanremo-black text-start font-normal mt-3 leading-7 tracking-wider"
        ></p>
      </div>
    </template>
  </ModalCourtain>
</template>

<script setup>
const props = defineProps({
  visibility: Boolean,
  promo: Object,
})

const { t } = useI18n({
  useScope: 'local',
})

const { isMobileOrTablet } = useDevice()

const emit = defineEmits(['close', 'delete'])

const cancel = () => {
  emit('close')
}
</script>
